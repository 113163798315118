<template>
  <div class="main-box" style="min-height: 80vh;background-color: #eaedf1;">
    <div class="ssss">
      <div class="top">
        <el-select v-model="selectType" size="small" placeholder="请选择">
          <el-option
            v-for="item in selectTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <a-input-search v-model="subject" placeholder="搜素商品" @search="onSearch" />
      </div>
      <div
        v-loading="loading"
      >
        <div class="type">
          <div v-for="(item,index) in typeList" :key="index" class="typeBox" @click="toSearchPage()">
            {{ item.cateName }}
          </div>
        </div>
        <div class="title-tip">
          <div>全部商品 <span class="num">{{ allNum }}/6</span></div>
          <a-button type="link" @click="toSearch()">
            查看全部 <i class="el-icon-arrow-right" />
          </a-button>
        </div>
        <VueSlickCarousel v-if="all" v-bind="settings" @afterChange="allafterChange">
          <div v-for="(aitem,index) in allList" :key="index+'a'" @click="toDetails(aitem)">
            <card :item="aitem" style="width:20%" />
          </div>
        </VueSlickCarousel>

        <div class="title-tip">
          <div>服饰热卖 <span class="num">{{ fuzhuangNum }}/6</span></div>
          <a-button type="link" @click="toSearch('衣服')">
            查看全部 <i class="el-icon-arrow-right" />
          </a-button>
        </div>
        <VueSlickCarousel v-if="fuzhunag" v-bind="settings" @afterChange="afterChange">
          <div v-for="(citem,index) in clothesList" :key="index+'c'" @click="toDetails(citem)">
            <card :item="citem" style="width:20%" />
          </div>
        </VueSlickCarousel>

        <div class="title-tip title-tip-mt">
          <div>3C热卖 <span class="num">{{ sancNum }}/6</span></div>
          <a-button type="link" @click="toSearch('电脑')">
            查看全部 <i class="el-icon-arrow-right" />
          </a-button>
        </div>
        <VueSlickCarousel v-if="tc" v-bind="settings" @afterChange="SafterChange">
          <div v-for="(titem,index) in tcList" :key="index+'t'" @click="toDetails(titem)">
            <card :item="titem" style="width:20%" />
          </div>
        </VueSlickCarousel>

        <div class="title-tip title-tip-mt">
          <div>家具热卖 <span class="num">{{ jiajuNum }}/6</span></div>
          <a-button type="link" @click="toSearch('家具')">
            查看全部 <i class="el-icon-arrow-right" />
          </a-button>
        </div>
        <VueSlickCarousel v-if="jiaju" v-bind="settings" @afterChange="JafterChange">
          <div v-for="(fitem,index) in furnitureList" :key="index+'f'" @click="toDetails(fitem)">
            <card :item="fitem" style="width:20%" />
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import card from './selectionlistcomponent/productCard.vue'
import { getAlibabaCategory, getBrandCategory, getAlibabaProductList, getBrandProductList } from '@/api/register'
export default {
  components: {
    card
  },
  data() {
    return {
      loading: true,
      selectType: '1',
      selectTypeList: [
        { label: '阿里巴巴', value: '1' },
        { label: '品牌', value: '2' }
      ],
      typeList: [],
      settings: {
        'dots': false,
        'arrows': true,
        'focusOnSelect': false,
        'infinite': false,
        'speed': 500,
        'slidesToShow': 5,
        'slidesToScroll': 5,
        'touchThreshold': 5,
        'draggable': false
      },
      fuzhunag: false,
      tc: false,
      jiaju: false,
      all: false,
      clothesList: [],
      tcList: [],
      furnitureList: [],
      allList: [],
      subject: '',

      // 分裂页数
      fuzhuangNum: 1,
      sancNum: 1,
      jiajuNum: 1,
      allNum: 1
    }
  },
  watch: {
    selectType(newValue) {
      this.loading = true
      if (newValue === '1') {
        this.typeList = []
        this.getAlibabaProductList()
      } else {
        this.getBrandCategory() // 分类
        this.getBrandProductList()
      }
    }
  },
  mounted() {
    this.getAlibabaProductList()
  },
  methods: {
    handleChange() {},
    onSearch() {
      this.$router.push({ path: '/searchproduct/index', query: { subject: this.subject, search: null }}) // search标签对象 { label: '手机', value: '555' }
    },
    toSearch(subject) {
      this.$router.push({ path: '/searchproduct/index', query: { subject: subject, search: null }})
    },

    /**
     * 获取阿里巴巴分类
     */
    getAlibabaCategory() {
      getAlibabaCategory().then(res => {
      })
    },

    /**
     * 获取品牌分类
     */
    getBrandCategory() {
      getBrandCategory().then(res => {
        if (res.data && res.data.length >= 10) {
          this.typeList = res.data.slice(0, 10)
        }
      })
    },

    /**
     * 获取1688的三个列表
     */
    getAlibabaProductList() {
      this.fuzhunag = false
      this.tc = false
      this.jiaju = false
      this.all = false
      // 服装
      getAlibabaProductList({ size: 30, subject: '衣服' }).then(res => {
        this.clothesList = res.data.records
        this.fuzhunag = true
        this.listloadding()
      })

      // 5
      getAlibabaProductList({ size: 30, subject: '电脑' }).then(res => {
        this.tcList = res.data.records
        this.tc = true
        this.listloadding()
      })

      // 5
      getAlibabaProductList({ size: 30, subject: '家具' }).then(res => {
        this.furnitureList = res.data.records
        this.jiaju = true
        this.listloadding()
      })

      // 全部
      getAlibabaProductList({ size: 30 }).then(res => {
        this.allList = res.data.records
        this.all = true
        this.listloadding()
      })
    },

    // 获取品牌的三个列表
    getBrandProductList() {
      this.fuzhunag = false
      this.tc = false
      this.jiaju = false
      this.all = false
      // categoryId: 5033,
      getBrandProductList({ size: 30, subject: '衣服' }).then(res => {
        this.clothesList = res.data.records
        this.fuzhunag = true
        this.listloadding()
      })

      // categoryId: 3889
      getBrandProductList({ size: 30, subject: '电脑' }).then(res => {
        this.tcList = res.data.records
        this.tc = true
        this.listloadding()
      })

      // categoryId: 6469
      getBrandProductList({ size: 30, subject: '家具' }).then(res => {
        this.furnitureList = res.data.records
        this.jiaju = true
        this.listloadding()
      })

      // 全部
      getBrandProductList({ size: 30 }).then(res => {
        this.allList = res.data.records
        this.all = true
        this.listloadding()
      })
    },
    // 加载中
    listloadding() {
      if (this.fuzhunag && this.tc && this.jiaju && this.all) {
        this.loading = false
      }
    },
    toDetails(item) {
      if (!item.productId) {
        return
      }
      this.$router.push({ path: '/detailproduct/index', query: { productId: item.productId, selectType: this.selectType, product: item.wholesalePrice }})
    },

    // 前往详情页面
    toSearchPage() {
      this.$router.push({ path: '/searchproduct/index' })
    },
    afterChange(index) {
      // 第一页
      switch (index) {
        case 0:
          this.fuzhuangNum = 1
          break
        case 5:
          this.fuzhuangNum = 2
          break
        case 10:
          this.fuzhuangNum = 3
          break
        case 15:
          this.fuzhuangNum = 4
          break
        case 20:
          this.fuzhuangNum = 5
          break
        case 25:
          this.fuzhuangNum = 6
          break
      }
    },
    SafterChange(index) {
      // 第一页
      switch (index) {
        case 0:
          this.sancNum = 1
          break
        case 5:
          this.sancNum = 2
          break
        case 10:
          this.sancNum = 3
          break
        case 15:
          this.sancNum = 4
          break
        case 20:
          this.sancNum = 5
          break
        case 25:
          this.sancNum = 6
          break
      }
    },
    JafterChange(index) {
      // 第一页
      switch (index) {
        case 0:
          this.jiajuNum = 1
          break
        case 5:
          this.jiajuNum = 2
          break
        case 10:
          this.jiajuNum = 3
          break
        case 15:
          this.jiajuNum = 4
          break
        case 20:
          this.jiajuNum = 5
          break
        case 25:
          this.jiajuNum = 6
          break
      }
    },
    allafterChange(index) {
      // 第一页
      switch (index) {
        case 0:
          this.allNum = 1
          break
        case 5:
          this.allNum = 2
          break
        case 10:
          this.allNum = 3
          break
        case 15:
          this.allNum = 4
          break
        case 20:
          this.allNum = 5
          break
        case 25:
          this.allNum = 6
          break
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.main-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(236, 163, 163);
  padding-bottom: 30px;
}
.ssss{
  background: #fff;
  margin-top: 30px;
  padding: 0 30px 30px 30px;
  max-width: 1100px;
  min-width: 1100px;
  min-height: 550px;
}

.top{
  display: flex;
  margin: 50px 0 30px;
}
.type{
  border-radius: 6px;
  margin-bottom: 35px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  .typeBox{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 20%;
    height: 68px;
  }
  .typeBox:hover{
    color: blue;
  }
}

.title-tip{
    height: 42px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #242833;
    .num{
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      color: #7a8499;
    }
}

.title-tip-mt{
  margin-top: 16px;
}

// 箭头
::v-deep .slick-prev:before{
  font-size: 29px;
  color: rgb(168, 168, 168);
}
::v-deep .slick-next:before{
  font-size: 29px;
  color: rgb(168, 168, 168);
}

</style>
