<template>
  <el-card shadow="hover" class="spu-card">
    <!-- 图片 -->
    <div class="image-s">
      <img class="image-i" :src="item.image" alt="" srcset="">
    </div>
    <div class="info">
      <div class="title">
        {{ item.subject }}
      </div>
    </div>
    <div class="sub-price">
      ¥{{ item.wholesalePrice }}
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => { return {} }
    }
  }
}
</script>

<style lang="scss" scoped>
.spu-card {
  cursor: pointer;
  width: 200px !important; // 200 大小
  display: flex;
  align-items: center;
}
.image-s {
  background-color: #fff;
  width: 200px;
  height: 200px;
  position: relative;
}
.image-i {
  object-fit: contain;
  object-position: center;
  height: 100%;
  z-index: 100;
}
.info {
  margin: 10px 12px 10px;
  width: 176px;
  height: 60px;
  border-bottom: 1px solid #e8e8e8;
}
.title {
  text-align: left;
  max-height: 48px;
  font-size: 14px;
  color: #242833;
  font-weight: 500;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.sub-price {
  margin-top: 8px;
  color: #474f5e;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  margin: 8px 12px;
}

::v-deep .el-card__body{
  padding: 0px !important;
}
</style>
